.layout-sider-logo {
  width: 180px;
  height: 60px;
  margin: 0 auto;
  background: url('../../public/assets/iControl-logo.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.nav-icon {
  width: 60px;
  height: 60px;
  background: url('../../public/assets/iQ-logo.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.route-back {
  background: url('../../public/assets/icons/route-back.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.ini-icon {
  display: inline-block;
  background: url('../../public/assets/icons/initiator.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.pr-icon {
  display: inline-block;
  background: url('../../public/assets/icons/process_reviewer.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.qr-icon {
  display: inline-block;
  background: url('../../public/assets/icons/qa_reviewer.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.qa-icon {
  display: inline-block;
  background: url('../../public/assets/icons/qa_approver.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.search-icon {
  background: url('../../public/assets/icons/search.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
}